//
// Content
//

// Desktop mode
@include media-breakpoint-up(xlg) {
  .app-content {
    padding-top: $app-content-padding-y;
    padding-bottom: $app-content-padding-y;
    padding-left: $app-content-padding-x;
    padding-right: $app-content-padding-x;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(xlg) {
  .app-content {
    max-width: none;
    padding-top: $app-content-padding-y-mobile;
    padding-bottom: $app-content-padding-y-mobile;
    padding-left: $app-content-padding-x-mobile;
    padding-right: $app-content-padding-x-mobile;
  }
}
